"use client";
// useLocalStorageSSR.ts
import { useState, useEffect } from "react";

export function useLocalStorageSafe<T>(
  key: string,
  initialValue: T,
): [T, (value: T | ((prev: T) => T)) => void] {
  // Store the current value
  const [value, setValue] = useState<T>(initialValue);

  // Track if we're mounted
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    // Once mounted, try to get the stored value
    try {
      const item = localStorage.getItem(key);
      if (item) {
        setValue(JSON.parse(item));
      }
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);
    }
  }, [key]);

  // Custom setter that updates both state and localStorage
  const setValueAndStorage = (newValue: T | ((prev: T) => T)) => {
    try {
      // Handle functional updates
      const valueToStore =
        newValue instanceof Function ? newValue(value) : newValue;

      // Update state
      setValue(valueToStore);

      // Only update localStorage if we're mounted (client-side)
      if (isMounted) {
        localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}":`, error);
    }
  };

  return [value, setValueAndStorage];
}
