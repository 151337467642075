import { clsx, type ClassValue } from "clsx";
import { Post } from "contentlayer/generated";
import { compareDesc } from "date-fns";
import { twMerge } from "tailwind-merge";

import siteMetadata from "../../lib/metadata";
import { StaticImport } from "next/dist/shared/lib/get-img-props";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertImagePathToS3(path: string | StaticImport) {
  return `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}${path}`;
}

export function isCloudFrontUrl(url: string | undefined | null) {
  if (!url) return false;
  const cloudFrontPattern = /cloudfront\.net/;
  return cloudFrontPattern.test(url);
}
export function calculateReadingTime(articleObject: any, wordsPerMinute = 225) {
  const paragraphs = articleObject.paragraphs || [];
  const totalWords = paragraphs.reduce((total: any, paragraph: any) => {
    const text = paragraph.t_text || "";
    const words = text.split(/\s+/).length;
    return total + words;
  }, 0);
  const estimatedReadingTime = totalWords / wordsPerMinute;
  const roundedReadingTime = Math.ceil(estimatedReadingTime);
  return roundedReadingTime;
}

export const getTagsWithCount = (posts: any) =>
  posts.reduce((acc: any, post: any) => {
    if (post.data.tags) {
      let tags =
        post?.data?.tags?.split(",").map((tag: any) => tag.trim()) || [];
      tags
        .filter((tag: any) => tag !== "" && tag !== "f&b")
        .forEach((tag: any) => {
          if (acc[tag]) {
            acc[tag] += 1;
          } else {
            acc[tag] = 1;
          }
        });
    }
    return acc;
  }, {});
export const getAllAuthors = (posts: any) => {
  let authorIds = new Set();
  const raw = posts.map((post: any) => {
    const authorData = post.data.article_body.author;

    //if Id is already accounted for skip over duplicate
    if (authorIds.has(authorData.p_author_id)) return null;

    //otherwise add to set and return the authorData
    authorIds.add(authorData.p_author_id);

    return authorData;
  });
  //filter out the null
  return raw.filter((value: any) => value && value.i_author);
};

export const getCategoriesWithCount = (posts: any) =>
  posts.reduce((acc: any, post: any) => {
    let category = post.data.category;
    if (acc[category]) {
      acc[category]++;
    } else {
      acc[category] = 1;
    }
    return acc;
  }, {});

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
  immediate?: boolean,
) {
  let timeout: ReturnType<typeof setTimeout> | null;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const sortByDate = (a: Post, b: Post) =>
  compareDesc(
    new Date(a.lastUpdatedDate || a.publishedDate),
    new Date(b.lastUpdatedDate || b.publishedDate),
  );

export const sortPostsByDate = (posts: any, option: "new" | "old") => {
  return posts.sort((a: any, b: any) => {
    const dateA = new Date(a?.data?.date_published ?? a.data.publishedAt);
    const dateB = new Date(b?.data?.date_published ?? b.data.publishedAt);
    return option === "new"
      ? dateB.getTime() - dateA.getTime()
      : dateA.getTime() - dateB.getTime();
  });
};

export const pageCount = (number: number) =>
  Math.ceil(number / siteMetadata.postsPerPage);

export function formatHeadlines(paragraphs: any) {
  return paragraphs.map((paragraph: any) => {
    const text = paragraph.t_headline.trim();
    const heading = paragraph.p_headline_type.trim();
    const slug = text.toLowerCase().replace(/\s+/g, "-");

    return {
      text,
      heading,
      slug,
    };
  });
}

export const getImageAlt = (file: string | undefined) => {
  return file ? file?.replace(".png", "")?.split("-")?.join(" ") : "image alt";
};

export const swapStories = (arr: any) => {
  const currentTime = new Date();
  const hour = currentTime.getHours();

  let swappedIndex;

  if (hour >= 6 && hour < 12) {
    // Morning:
    swappedIndex = 0;
    arr.splice(0, 1);
  } else if (hour >= 12 && hour < 18) {
    // Afternoon:
    swappedIndex = 1;
    arr.splice(1, 1);
  } else {
    // Evening:
    swappedIndex = 2;
    arr.splice(2, 1);
  }
  // Return the index of the swapped item
  return swappedIndex;
};

export function convertToWebp(str: string) {
  if (!str) return "";
  let newExtension = ".webp";
  let regex = /\.(png|jpg)$/i;

  return str.replace(regex, newExtension);
}

export function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}
